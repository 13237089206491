import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueCookies from "vue-cookies";
import VueRouter from "vue-router";
import router from "@/router";
import tempData from "./modules/tempData";
import global from "./modules/global";
import selectedAgent from "./modules/selectedAgent";
import { SYSTEM_TYPES } from "@/helpers/constants.js";

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VueCookies);
var chatTimePingPong;
var chatModeInterval;
let isChatModeRequested = false;

export default new Vuex.Store({
  modules: {
    tempData,
    global,
    selectedAgent
  },
  state: {
    permission: false,
    timestamp: "",
    selectedBrand: null,
    brandID: router.currentRoute.params.brandID,
    userPermission: true,
    brandLogo: "",
    brandName: "",
    brandLists: [],
    brandSetting: {},
    brandColor: "",
    order: {
      brandID: null,
      brandLogo: "",
      brandName: "",
      brandLists: [],
      brandColor: "",
      timestamp: ""
    },
    isStore: 0,
    isLoading: false,
    isCheckChangeChat: false,
    isLoadingProfileDetail: false,
    branchID: null,
    useThe1Card: true,
    reloadListFlag: false,
    isReload: false,
    isDisableSubmitButton: false,

    //chat
    chatModeID: 0,
    chatSocialChannelID: 99,
    chatAdminProfile: {
      id: "",
      externalId: "",
      isAvailable: false
    },
    chat: {
      tierList: [{ id: 0, name: "Please Select" }]
    },
    isLoadChatConversationList: false,
    isLoadChatMessageList: false,
    chatMessageDummy: [],
    chatConversationUpdate: [],
    chatSocialList: [],
    isShowCloseButtonChatOrder: false,
    chatTextReplyList: [],
    isLoadingChatTextReply: false,
    isChatTranslate: false,
    chatUserGUIDSelected: "",
    chatUserAddressList: [],
    imgUrlCurrentChat: "",
    nameCurrentChat: "",
    displayNameCurrentChat: "",
    chatModeCount: [],
    chatModeLabel: {},
    chatSocialChannelList: [{ id: 99, name: "All Channel", imageUrl: "" }],
    selectedUserChatList: [],
    displayCheckboxSelectUserChat: false,
    conversationTopicList: [],

    //Global Alert
    isSocketOpen: false,
    isShowGlobalAlert: false,
    messageGlobalAlert: "",
    messageGlobalAlertSecondLine: "",
    isShowGlobalAlertError: false,
    messageGlobalAlertError: "",
    messageGlobalAlertErrorSecondLine: "",
    isShowGlobalAlertWarning: false,
    messageGlobalAlertWarning: "",
    messageGlobalAlertWarningSecondLine: "",
    newAdminChatName: { chatID: "", adminName: "" },
    newChatItem: { chatroom: {}, isToAdmin: "", mode: 0 },
    lastChatItem: { chatroomId: "", userType: "", message: "" },

    // show/hide tab
    isShowHeadertab: true,
    isShowProfileTab: true,
    username: ""
  },
  mutations: {
    UPDATE_PERMISSION: (state, payload) => {
      Vue.set(state, "permission", payload);
    },
    UPDATE_TIMESTAMP: (state, payload) => {
      Vue.set(state, "timestamp", payload);
    },
    UPDATE_TIMESTAMP_ORDER: (state, payload) => {
      Vue.set(state, "timestamp", payload);
    },
    UPDATE_BRAND: (state, payload) => {
      Vue.set(state, "brandID", payload);
    },
    UPDATE_BRAND_ORDER: (state, payload) => {
      Vue.set(state, "brandID", payload);
    },
    UPDATE_BRANCH: (state, payload) => {
      Vue.set(state, "branchID", payload);
    },
    UPDATE_USER_PERMISSION: (state, payload) => {
      Vue.set(state, "userPermission", payload);
    },
    UPDATE_BRAND_LOGO: (state, payload) => {
      Vue.set(state, "brandLogo", payload);
    },
    UPDATE_BRAND_LOGO_ORDER: (state, payload) => {
      Vue.set(state, "brandLogo", payload);
    },
    UPDATE_BRAND_NAME: (state, payload) => {
      Vue.set(state, "brandName", payload);
    },
    UPDATE_BRAND_NAME_ORDER: (state, payload) => {
      Vue.set(state, "brandName", payload);
    },
    UPDATE_BRAND_LISTS: (state, payload) => {
      Vue.set(state, "brandLists", payload);
    },
    UPDATE_BRAND_SETTING: (state, payload) => {
      Vue.set(state, "brandSetting", payload);
    },
    UPDATE_BRAND_LISTS_ORDER: (state, payload) => {
      Vue.set(state, "brandLists", payload);
    },
    UPDATE_STORE_FLAG: (state, payload) => {
      Vue.set(state, "isStore", payload);
    },
    UPDATE_BRAND_COLOR: (state, payload) => {
      Vue.set(state, "brandColor", payload);
    },
    UPDATE_BRAND_COLOR_ORDER: (state, payload) => {
      Vue.set(state, "brandColor", payload);
    },
    UPDATE_SELECTED_BRAND: (state, payload) => {
      Vue.set(state, "selectedBrand", payload);
    },
    UPDATE_LOADING: (state, payload) => {
      Vue.set(state, "isLoading", payload);
    },
    UPDATE_CHEACK_CHANGE_CHAT: (state, payload) => {
      Vue.set(state, "isCheckChangeChat", payload);
    },
    UPDATE_LOADING_PROFILE_DETAIL: (state, payload) => {
      Vue.set(state, "isLoadingProfileDetail", payload);
    },
    UPDATE_USER_THEONECARD: (state, payload) => {
      Vue.set(state, "useThe1Card", payload);
    },
    UPDATE_RELOAD_LIST_FLAG: (state, payload) => {
      Vue.set(state, "reloadListFlag", payload);
    },
    UPDATE_IS_RELOAD: (state, payload) => {
      Vue.set(state, "isReload", payload);
    },
    UPDATE_IS_DISABLE_SUBMIT_BUTTON: (state, payload) => {
      Vue.set(state, "isDisableSubmitButton", payload);
    },

    //chat
    UPDATE_CHAT_MODE: (state, payload) => {
      Vue.set(state, "chatModeID", payload);
    },
    UPDATE_CHAT_SOCIAL_CHANNEL: (state, payload) => {
      Vue.set(state, "chatSocialChannelID", payload);
    },
    SET_CHAT_ADMIN_PROFILE: (state, payload) => {
      Vue.set(state, "chatAdminProfile", payload);
    },
    SET_SOCKET_OPEN: (state, payload) => {
      Vue.set(state, "isSocketOpen", payload);
    },
    UPDATE_LOAD_CHAT_CONVERSATION_LIST: (state, payload) => {
      Vue.set(state, "isLoadChatConversationList", payload);
    },
    UPDATE_LOAD_CHAT_MESSAGE_LIST: (state, payload) => {
      Vue.set(state, "isLoadChatMessageList", payload);
    },
    UPDATE_CHAT_MESSAGE_DUMMY: (state, payload) => {
      Vue.set(state, "chatMessageDummy", payload);
    },
    UPDATE_CHAT_CONVERSATION_UPDATE: (state, payload) => {
      Vue.set(state, "chatConversationUpdate", payload);
    },
    UPDATE_CHAT_SOCIAL_LIST: (state, payload) => {
      Vue.set(state, "chatSocialList", payload);
    },
    UPDATE_SHOW_CLOSE_BUTTON_CHATORDER: (state, payload) => {
      Vue.set(state, "isShowCloseButtonChatOrder", payload);
    },
    UPDATE_CHAT_TEXT_REPLY_LIST: (state, payload) => {
      Vue.set(state, "chatTextReplyList", payload);
    },
    SPLICE_TEXT_REPLY_LIST: (state, payload) => {
      state.chatTextReplyList.splice(payload.idx, 1);
    },
    UPDATE_ISLOADING_TEXT_REPLY_LIST: (state, payload) => {
      Vue.set(state, "isLoadingChatTextReply", payload);
    },
    UPDATE_CHAT_ISTRANSLATE: (state, payload) => {
      Vue.set(state, "isChatTranslate", payload);
    },
    UPDATE_CHAT_USER_GUID_SELECTED: (state, payload) => {
      Vue.set(state, "chatUserGUIDSelected", payload);
    },
    UPDATE_CHAT_USER_ADDRESS_LIST: (state, payload) => {
      Vue.set(state, "chatUserAddressList", payload);
    },
    UPDATE_CHAT_MODE_COUNT: (state, payload) => {
      Vue.set(state, "chatModeCount", payload);
    },
    UPDATE_CHAT_MODE_LABEL: (state, payload) => {
      Vue.set(state, "chatModeLabel", payload);
    },
    SET_CHAT_SOCIAL_CHANNEL_LIST: (state, payload) => {
      Vue.set(state, "chatSocialChannelList", payload);
    },
    SET_SELECTED_USER_CHAT_LIST: (state, payload) => {
      Vue.set(state, "selectedUserChatList", payload);
    },
    SET_DISPALY_CHECKBOX_SELECTE_USER_CHAT: (state, payload) => {
      Vue.set(state, "displayCheckboxSelectUserChat", payload);
    },
    SET_CONVERSATION_TOPIC_LIST: (state, payload) => {
      Vue.set(state, "conversationTopicList", payload);
    },

    //Global Alert -----------
    setShowGlobalAlert(state, val) {
      state.isShowGlobalAlert = val;
    },
    setMessageGlobalAlert(state, val) {
      state.messageGlobalAlert = val;
    },
    setMessageGlobalAlertSecondLine(state, val) {
      state.messageGlobalAlertSecondLine = val;
    },
    setShowGlobalAlertError(state, val) {
      state.isShowGlobalAlertError = val;
    },
    setMessageGlobalAlertError(state, val) {
      state.messageGlobalAlertError = val;
    },
    setMessageGlobalAlertErrorSecondLine(state, val) {
      state.messageGlobalAlertErrorSecondLine = val;
    },
    setShowGlobalAlertWarning(state, val) {
      state.isShowGlobalAlertWarning = val;
    },
    setMessageGlobalAlertWarning(state, val) {
      state.messageGlobalAlertWarning = val;
    },
    setMessageGlobalAlertWarningSecondLine(state, val) {
      state.messageGlobalAlertWarningSecondLine = val;
    },
    setImgUrlCurrentChat(state, val) {
      state.imgUrlCurrentChat = val;
    },
    setNameCurrentChat(state, val) {
      state.nameCurrentChat = val;
    },
    setDispalyNameCurrentChat(state, val) {
      state.displayNameCurrentChat = val;
    },
    setNewAdminChatName(state, val) {
      state.newAdminChatName = val;
    },
    setNewChatItem(state, val) {
      state.newChatItem = val;
    },
    setLastChatItem(state, payload) {
      Vue.set(state, "lastChatItem", payload);
    },

    // show/hide tab
    setShowHeaderTab(state, val) {
      state.isShowHeaderTab = val;
    },
    setShowProfileTab(state, val) {
      state.isShowProfileTab = val;
    },
    setUsername(state, val) {
      state.username = val;
    },

    // chat
    UPDATE_CHAT_TEIR_LIST: (state, payload) => {
      state.chat.tierList = payload;
    }
  },
  actions: {
    setPermission(context, payload) {
      context.commit("UPDATE_PERMISSION", payload);
    },
    setTimestamp(context, payload) {
      context.commit("UPDATE_TIMESTAMP", payload);
    },
    setTimestampOrder(context, payload) {
      context.commit("UPDATE_TIMESTAMP_ORDER", payload);
    },
    setBrand(context, payload) {
      context.commit("UPDATE_BRAND", payload);
    },
    setBrandOrder(context, payload) {
      context.commit("UPDATE_BRAND_ORDER", payload);
    },
    setBranch(context, payload) {
      context.commit("UPDATE_BRANCH", payload);
    },
    setUserPermission(context, payload) {
      context.commit("UPDATE_USER_PERMISSION", payload);
    },
    setBrandLogo(context, payload) {
      context.commit("UPDATE_BRAND_LOGO", payload);
    },
    setBrandLogoOrder(context, payload) {
      context.commit("UPDATE_BRAND_LOGO_ORDER", payload);
    },
    setBrandName(context, payload) {
      context.commit("UPDATE_BRAND_NAME", payload);
    },
    setBrandNameOrder(context, payload) {
      context.commit("UPDATE_BRAND_NAME_ORDER", payload);
    },
    setBrandLists(context, payload) {
      context.commit("UPDATE_BRAND_LISTS", payload);
    },
    setBrandSetting(context, payload) {
      context.commit("UPDATE_BRAND_SETTING", payload);
    },
    setBrandListsOrder(context, payload) {
      context.commit("UPDATE_BRAND_LISTS_ORDER", payload);
    },
    setStoreFlag(context, payload) {
      context.commit("UPDATE_STORE_FLAG", payload);
    },
    setBrandColor(context, payload) {
      context.commit("UPDATE_BRAND_COLOR", payload);
    },
    setBrandColorOrder(context, payload) {
      context.commit("UPDATE_BRAND_COLOR_ORDER", payload);
    },
    setSelectedBrand(context, payload) {
      context.commit("UPDATE_SELECTED_BRAND", payload);
    },
    setIsLoading(context, payload) {
      context.commit("UPDATE_LOADING", payload);
    },
    setTheOneCardFlag(context, payload) {
      context.commit("UPDATE_USER_THEONECARD", payload);
    },
    setReloadListFlag(context, payload) {
      context.commit("UPDATE_RELOAD_LIST_FLAG", payload);
    },
    setIsReload(context, payload) {
      context.commit("UPDATE_IS_RELOAD", payload);
    },
    setIsDisableSubmitButton(context, payload) {
      context.commit("UPDATE_IS_DISABLE_SUBMIT_BUTTON", payload);
    },

    //CHAT
    setChatMode(context, payload) {
      context.commit("UPDATE_CHAT_MODE", payload);
    },
    setChatSocialChannel(context, payload) {
      context.commit("UPDATE_CHAT_SOCIAL_CHANNEL", payload);
    },
    handleChatSocket: ({ commit, getters, dispatch }, payLoad) => {
      if (payLoad.chatAdminProfile.externalId) {
        commit("SET_SOCKET_OPEN", true);
      } else {
        return;
      }
      // let pathConnection = "wss://fb93tbm2o6.execute-api.ap-southeast-1.amazonaws.com/dev";
      let pathConnection = `${Vue.prototype.$chatBaseUrlSocket}`;
      let socket = new WebSocket(pathConnection);
      let config = {
        action: "onRegister",
        brandId: parseInt(payLoad.brandID), //payLoad.brandID.toString()
        userGUID: payLoad.chatAdminProfile.externalId
        // userGUID: "48f57dd7-5c51-4ade-8def-e19b6b60e5e8"
      };
      socket.onopen = function() {
        socket.send(JSON.stringify(config));
        clearInterval(chatTimePingPong);
        clearInterval(chatModeInterval);

        chatModeInterval = setInterval(() => {
          isChatModeRequested = false;
        }, 5000);

        // ส่ง ping ทุก 15 วินาที
        chatTimePingPong = setInterval(() => {
          if (socket.readyState === socket.OPEN) {
            socket.send(JSON.stringify({ action: "ping" }));
          } else {
            clearInterval(chatTimePingPong);
            commit("SET_SOCKET_OPEN", false);
          }
        }, 15000);
      };

      socket.onmessage = event => {
        // console.log(event);
        let eventData = JSON.parse(event.data);
        console.log(eventData);
        if (eventData.message == "pong") {
          return;
        }

        //Web Push Notification
        // try {
        //   if (messageRes.message && messageRes.userType == "user") {
        //     Notification.requestPermission(function(permission) {
        //       if (permission === "granted") {
        //         if ("serviceWorker" in navigator) {
        //           navigator.serviceWorker.ready.then(function(registration) {
        //             registration.showNotification(messageRes.message || "-");
        //           });
        //         }
        //       }
        //     });
        //   }
        // } catch (ero) {
        //   console.log(ero.message);
        // }
        if (getters.getChatSocialChannel)
          if (eventData.adminName) {
            commit("setNewAdminChatName", {
              chatID: eventData.chatroomId,
              adminName: eventData.adminName
            });
          }
        if (eventData.mode == 2) {
          commit("setNewAdminChatName", {
            chatID: eventData.chatroomId,
            adminName: "Bot Mode"
          });
        }

        if (
          (eventData.userType == "system" &&
            eventData.systemType == SYSTEM_TYPES.AssignTo) ||
          (eventData.userType == "system" &&
            eventData.systemType == SYSTEM_TYPES.MoveToPending &&
            eventData.mode == 4) ||
          (eventData.userType == "system" && [3, 5].includes(eventData.mode)) ||
          (eventData.userType == "admin" && eventData.mode == 3) ||
          (eventData.userType == "user" && eventData.mode == 2) ||
          (eventData.userType == "user" && eventData.mode == 3) ||
          (eventData.userType == "system" &&
            eventData.systemType == SYSTEM_TYPES.AdminLeaveGroupMention)
        ) {
          console.log(eventData.systemType == SYSTEM_TYPES.AssignTo);
          commit("setLastChatItem", { ...eventData });
        }
        if (
          (eventData.isToAdmin == true || eventData.isToAdmin == false) &&
          eventData.userType == "system" &&
          eventData.chatroom
        ) {
          commit("setNewChatItem", {
            chatroom: eventData.chatroom,
            isToAdmin: eventData.isToAdmin,
            chatroomId: eventData.chatRoomId,
            mode: eventData.mode || 0
          });
        } else if (eventData.userType == "user" && eventData.isFirstMessage) {
          commit("setNewChatItem", {
            chatroom: eventData,
            mode: eventData.mode || 0,
            isFirstMessage: true
          });
        }

        commit("UPDATE_LOAD_CHAT_CONVERSATION_LIST", false);
        commit("UPDATE_LOAD_CHAT_MESSAGE_LIST", false);
        setTimeout(() => {
          let tempChatMessDump = getters.getChatMessageDummy;
          if (eventData.messageType == 1) {
            eventData.message = eventData.message?.replace(
              /(https?:\/\/[^\s]+)/g,
              url => `<a href="${url}" target="_blank">${url}</a>`
            );
            eventData.message = eventData.message?.replace(
              /@\{([^}]+)\}/g,
              `<span style="color: blue;">@$1</span>`
            );
          }

          let tempChatCon = getters.getChatConversationUpdate;
          commit("UPDATE_CHAT_MESSAGE_DUMMY", [...tempChatMessDump, eventData]);

          if (eventData.systemType != SYSTEM_TYPES.IsSendSuccess) {
            commit("UPDATE_CHAT_CONVERSATION_UPDATE", [
              ...tempChatCon,
              eventData
            ]);
          }

          const isEmpty = Object.keys(eventData).length === 0;
          if (!isChatModeRequested && !isEmpty) {
            isChatModeRequested = true;
            dispatch("getChatModeListCount");
          }

          commit("UPDATE_LOAD_CHAT_CONVERSATION_LIST", true);
          commit("UPDATE_LOAD_CHAT_MESSAGE_LIST", true);
        }, 1);
      };
      socket.onerror = function() {
        // console.error("WebSocket error observed:", event);
        clearInterval(chatTimePingPong);
        commit("SET_SOCKET_OPEN", false);
      };
      socket.onclose = () => {
        clearInterval(chatTimePingPong);
        commit("SET_SOCKET_OPEN", false);
        clearInterval(chatModeInterval);
        isChatModeRequested = false;
      };
    },
    getChatTextReplyAdmin: async ({ state, commit }) => {
      if (!state.isLoadingChatTextReply) {
        commit("UPDATE_ISLOADING_TEXT_REPLY_LIST", true);
        commit("UPDATE_CHAT_TEXT_REPLY_LIST", []);
        await axios({
          url: `${Vue.prototype.$chatBaseUrl}/reply/Find`,
          headers: { ...Vue.prototype.$headersChat },
          method: "post",
          data: {
            userGUID: state.chatAdminProfile.externalId,
            Shortkeys: "",
            brandID: state.brandID
          }
        })
          .then(res => {
            let data = res.data;
            if (data.result) {
              commit("UPDATE_CHAT_TEXT_REPLY_LIST", data.detail);
            }
            commit("UPDATE_ISLOADING_TEXT_REPLY_LIST", false);
          })
          .catch(error => {
            commit("UPDATE_ISLOADING_TEXT_REPLY_LIST", false);
            console.log(error.message);
          });
      }
    },
    getChatAdminProfile: async ({ commit, dispatch }) => {
      if (VueCookies.isKey("d-order-session-token")) {
        await axios({
          url: `${Vue.prototype.$chatBaseUrl}/user/token`,
          headers: { ...Vue.prototype.$headersChat },
          method: "post",
          data: {
            token: VueCookies.get("d-order-session-token")
          }
        })
          .then(async res => {
            let data = res.data;
            data.detail.teamIds = [];
            await data.detail.teams.forEach(async team => {
              await team.teamsPaticipant.forEach(async item => {
                data.detail.teamIds.push(item.adminExternalId);
              });
            });
            VueCookies.set("d-order-username", data.detail.email);

            if (data.result) {
              commit("SET_CHAT_ADMIN_PROFILE", data.detail);
              dispatch("getChatTextReplyAdmin");
            }
          })
          .catch(error => {
            console.log(error.message);
          });
      } else {
        VueRouter.push({ path: "/" });
      }
    },
    updateChatAdminProfileIsAvailable: async ({ state, dispatch }, payload) => {
      if (VueCookies.isKey("d-order-session-token")) {
        await axios({
          url: `${Vue.prototype.$chatBaseUrl}/user/staff/updateStatus`,
          headers: { ...Vue.prototype.$headersChat },
          method: "post",
          data: {
            externalId: state.chatAdminProfile.externalId,
            isAvailable: payload,
            brandId: state.brandID
          }
        })
          .then(res => {
            let data = res.data;

            if (data.result) {
              dispatch("getChatAdminProfile");
            }
          })
          .catch(error => {
            console.log(error.message);
          });
      } else {
        VueRouter.push({ path: "/" });
      }
    },
    handleChangeChatTranslate: async ({ state, commit }, payload) => {
      await axios({
        url: `${Vue.prototype.$chatBaseUrl}/translate/user`,
        headers: { ...Vue.prototype.$headersChat },
        method: "post",
        data: {
          userId: payload.userId,
          isTranslate: state.isChatTranslate
        }
      })
        .then(res => {
          let data = res.data;
          if (!data.result || !data.detail) {
            commit("UPDATE_CHAT_ISTRANSLATE", !state.isChatTranslate);
          }
        })
        .catch(error => {
          commit("UPDATE_CHAT_ISTRANSLATE", !state.isChatTranslate);
          console.log(error.message);
        });
    },
    handleGetChatAddressList: async ({ state, commit }) => {
      await axios({
        url: `${Vue.prototype.$baseUrlOrder}/api/user/${state.chatUserGUIDSelected}/address/list`,
        // headers: { ...Vue.prototype.$headersChat },
        method: "get"
      })
        .then(res => {
          let data = res.data;
          if (data.result) {
            commit("UPDATE_CHAT_USER_ADDRESS_LIST", data.detail);
          }
        })
        .catch(error => {
          console.log(error.message);
        });
    },
    handleTierList: async ({ commit }, payload) => {
      await axios({
        url: `${Vue.prototype.$chatBaseUrl}/customer/tier/list/${payload}`,
        // headers: { ...Vue.prototype.$headersChat },
        method: "get"
      })
        .then(res => {
          let data = res.data;
          if (data.result) {
            let list = [{ id: 0, name: "Please Select" }];
            let result = list.concat(data.detail);
            commit("UPDATE_CHAT_TEIR_LIST", result);
          }
        })
        .catch(error => {
          console.log(error.message);
        });
    },
    setChatModeCount(context, payload) {
      context.commit("UPDATE_CHAT_MODE_COUNT", payload);
    },
    setChatModeLabel: async ({ state, commit }) => {
      await fetch(
        `${Vue.prototype.$chatBaseUrl}/chatroom/chatmode-master-data?brandId=${state.brandID}`,
        {
          method: "GET",
          headers: new Headers(Vue.prototype.$headersChat)
        }
      )
        .then(response => {
          return response.json();
        })
        .then(result => {
          if (result.result) {
            let obj = {};
            result.detail.forEach(item => {
              obj[item.modeId] = item;
            });
            commit("UPDATE_CHAT_MODE_LABEL", obj);
          } else {
            console.log("error setChatModeLabel");
          }
        })
        .catch(error => {
          console.log(error.message);
        });
    },
    getChatModeListCount: async ({ state, commit }) => {
      await fetch(
        `${Vue.prototype.$chatBaseUrl}/chatroom/chatmode?brandId=${state.brandID}`,
        {
          method: "GET",
          headers: new Headers(Vue.prototype.$headersChat)
        }
      )
        .then(response => {
          return response.json();
        })
        .then(result => {
          if (result.result) {
            commit("UPDATE_CHAT_MODE_COUNT", result.detail);
          } else {
            console.log("error getChatModeListCount");
          }
        })
        .catch(error => {
          console.log(error.message);
        });
    },
    setChatSocialChannelList(context, payload) {
      let list = [{ id: 99, name: "All Channel", imageUrl: "" }];
      let listNew = list.concat(payload);
      context.commit("SET_CHAT_SOCIAL_CHANNEL_LIST", listNew);
    },
    setSelectedUserChatList(context, payload) {
      context.commit("SET_SELECTED_USER_CHAT_LIST", payload);
    },
    setDisplayCheckboxSelectUserChat(context, payload) {
      context.commit("SET_DISPALY_CHECKBOX_SELECTE_USER_CHAT", payload);
    },
    setConversationTopicList: async ({ state, commit }) => {
      await fetch(
        `${Vue.prototype.$chatBaseUrl}/message/topics/${state.brandID}`,
        {
          method: "GET",
          headers: new Headers(Vue.prototype.$headersChat)
        }
      )
        .then(response => {
          return response.json();
        })
        .then(result => {
          if (result.result) {
            let list = [{ id: 0, name: "Please Select Conversation Topic" }];
            let listNew = list.concat(result.detail);
            commit("SET_CONVERSATION_TOPIC_LIST", listNew);
          } else {
            console.log("error getConversationTopicList");
          }
        })
        .catch(error => {
          console.log(error.message);
        });
    }
    // handleTokenUnauthorize() {
    //   console.log(VueRouter.pa);
    //   VueRouter.push({path: "/"})
    // },
  },
  getters: {
    // getPermission: state => () => {
    //   return state.permission;
    // }
    getPermission: state => {
      return state.permission;
    },
    getTimestamp: state => {
      return state.timestamp;
    },
    getTimestampOrder: state => {
      return state.order.timestamp;
    },
    getBrand: state => {
      return state.brandID;
    },
    getSelectedBrand: state => {
      return state.selectedBrand;
    },
    getBrandOrder: state => {
      return state.order.brandID;
    },
    getBranch: state => {
      return state.branchID;
    },
    getUserPermission: state => {
      return state.userPermission;
    },
    getBrandLogo: state => {
      return state.brandLogo;
    },
    getBrandLogoOrder: state => {
      return state.order.brandLogo;
    },
    getBrandName: state => {
      return state.brandName;
    },
    getBrandNameOrder: state => {
      return state.order.brandName;
    },
    getBrandLists: state => {
      return state.brandLists;
    },
    getBrandSetting: state => {
      return state.brandSetting;
    },
    getBrandListsOrder: state => {
      return state.order.brandLists;
    },
    getStoreFlag: state => {
      return state.isStore;
    },
    getBrandColor: state => {
      return state.brandColor;
    },
    getBrandColorOrder: state => {
      return state.order.brandColor;
    },
    getIsLoading: state => {
      return state.isLoading;
    },
    getTheOneCardFlag: state => {
      return state.useThe1Card;
    },
    getReloadListFlag: state => {
      return state.reloadListFlag;
    },
    getIsReload: state => {
      return state.isReload;
    },
    getIsDisableSubmitButton: state => {
      return state.isDisableSubmitButton;
    },

    //chat
    getChatMode: state => {
      return state.chatModeID;
    },
    getChatSocialChannel: state => {
      return state.chatSocialChannelID;
    },
    getChatAdminProfile: state => {
      return state.chatAdminProfile;
    },
    getChatMessageDummy: state => {
      return state.chatMessageDummy;
    },
    getChatConversationUpdate: state => {
      return state.chatConversationUpdate;
    },
    getChatSocialList: state => {
      return state.chatSocialList;
    },
    getChatTextReplyList: state => {
      return state.chatTextReplyList;
    },
    getChatUserAddressList: state => {
      return state.chatUserAddressList;
    },
    getImgUrlCurrentChat: state => {
      return state.imgUrlCurrentChat;
    },
    getNameCurrentChat: state => {
      return state.nameCurrentChat;
    },
    getDisplayNameCurrentChat: state => {
      return state.displayNameCurrentChat;
    },
    getNewAdminChatName: state => {
      return state.newAdminChatName;
    },
    getNewChatItem: state => {
      return state.newChatItem;
    },
    getChatModeCount: state => {
      return state.chatModeCount;
    },
    getChatModeLabel: state => {
      return state.chatModeLabel;
    },
    getChatSocialChannelList: state => {
      return state.chatSocialChannelList;
    },
    getSelectedUserChatList: state => {
      return state.selectedUserChatList;
    },
    getDisplayCheckboxSelectUserChat: state => {
      return state.displayCheckboxSelectUserChat;
    },
    getConversationTopicList: state => {
      return state.conversationTopicList;
    },

    // show/hide tab
    getIsShowHeaderTab: state => {
      return state.isShowHeaderTab;
    },
    getIsShowProfileTab: state => {
      return state.isShowProfileTab;
    },
    getUsername: state => {
      return state.username;
    },

    // chat
    getTierList: state => {
      return state.chat.tierList;
    }
  }
});
