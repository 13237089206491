require("dotenv").config();

const fetchIconsFromAPI = async () => {
  try {
    const FIXBRANDID =
      process.env[`VUE_APP_FIX_BRAND_ID_${process.env.VUE_APP_MODE}`];
    if (!FIXBRANDID) throw new Error("Missing FIXBRANDID");
    const BASE_API =
      process.env[`VUE_APP_BASE_URL_${process.env.VUE_APP_MODE}`];
    const response = await fetch(
      `${BASE_API}/api/Brand/GetAnonymous/${FIXBRANDID}`
    );
    const data = await response.json();

    console.log(data);
    return data;
  } catch (error) {
    console.error("Failed to fetch icons from API:", error);

    return null;
  }
};

module.exports = { fetchIconsFromAPI };
